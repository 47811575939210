export const LocalStorage = {
  get(key: string, isParse: boolean = true) {
    const storageData = localStorage.getItem(key);

    return isParse ? JSON.parse(storageData as string) : storageData;
  },
  set(key: string,
    item: any,
    isStringify = true) {
    localStorage.setItem(key, isStringify ? JSON.stringify(item) : item);
  },
  remove(key: string) {
    localStorage.removeItem(key);
  },
};