import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authentication/authentication.slice';
import sidebarReducer from '../entities/sidebar/store/slice';
import { authApi } from './authentication/authentication.actions';
import { sidebarApi } from '../entities/sidebar/store/actions';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
    sidebar: sidebarReducer,
    [sidebarApi.reducerPath]: sidebarApi.reducer,
  },
  // eslint-disable-next-line max-len
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authApi.middleware, sidebarApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
