import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppPage from 'view/AppPage';
import LoginPage from 'view/LoginPage';
import { Provider } from 'react-redux';
import { store } from './store';
import './App.less';
import PrivateRoute from './utils/PrivateRoute';

const App: React.FC = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PrivateRoute/>}>
          <Route path="/" element={<AppPage/>}/>
        </Route>
        <Route
          path="/login"
          element={<LoginPage/>}
        />
      </Routes>
    </BrowserRouter>
  </Provider>
);
export default App;
