const authWorker = () => {
  // eslint-disable-next-line no-restricted-globals
  self.addEventListener('message', (e) => {
    if (!e) return;

    let savedToken: string = '';

    const { data } = e;
    const { token, action } = data;

    if (action === 'SET') {
      savedToken = token;
      postMessage(savedToken);
    } else if (action === 'GET') {
      postMessage(savedToken);
    }
  });
};

export default authWorker;