import React, { FC } from 'react';
import {
    Button, Form, Input, Typography,
} from 'antd';
import { Link } from 'react-router-dom';
import { LoginFormValues } from 'types';
import { SInputGroup, Actions } from '../LoginPage.styles';
import { CustomMaskedInput } from '../../../components/CustomMaskedInput/CustomMaskedInput';
import { CustomInput } from '../../../components/CustomInput/CustomInput';
import { CustomButton } from '../../../components/CustomButton/CustomButton';

type LoginFormProps = {
    onFinish: (values: LoginFormValues) => void,
    error?: string
};

const stringRegEx = new RegExp(/^[a-zA-Z0-9]+$/);

const { Paragraph, Text } = Typography;

const LoginForm: FC<LoginFormProps> = ({ onFinish, error }) => (
    <Form
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
    >
        <Text style={{display:'block', textAlign:'center'}} type="danger">{error}</Text>
        <SInputGroup>
            <Form.Item
                label="Email:"
                name="username"
                validateTrigger={['onBlur', 'onChange']}
                validateFirst
                rules={[
                    {
                        required: true,
                        message: 'Please input your email',
                    }
                ]}
            >
                <CustomInput
                    type="text"
                    bgColor="white"
                    autoComplete="new-password"
                />
            </Form.Item>
            <Form.Item
                label="Password:"
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password',
                    },
                ]}
            >
                <CustomInput
                    type="password"
                    bgColor="white"
                    placeholder="*************"
                    autoComplete="new-password"
                    data-testid="passwordInput"
                />
            </Form.Item>
        </SInputGroup>

        <Form.Item>
            <CustomButton type="primary" block buttonSize="large" htmlType="submit" >
                Log in
            </CustomButton>
        </Form.Item>

        <Actions>
            <span>Forgot Password?</span>
            {/*<Link to="/forgot">Forgot Password?</Link>*/}
        </Actions>

    </Form>
);

export default LoginForm;
