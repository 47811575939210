import { TThemeColor } from 'styles/theme';
import {
  FlattenInterpolation,
  DefaultTheme,
  ThemedStyledProps,
  FlattenSimpleInterpolation,
  css,
} from 'styled-components';

type TIfPropVal = FlattenInterpolation<ThemedStyledProps<any, DefaultTheme>>;

export const getColor = (colorName: TThemeColor) => (props: any) => props.theme[colorName];
export const getColorByProp =
  (propName: string, fallback: string = 'none') =>
  (props: any) =>
    props.theme[props[propName]] || fallback;
export const getColorIf =
  (propFlagName: string, ifColorName: TThemeColor, elseColorName: TThemeColor) => (props: any) =>
    props[propFlagName] ? props.theme[ifColorName] : props.theme[elseColorName] || 'transparent';
export const getProp =
  (propName: string, fallbackVal: string | number = '') =>
  (props: any) =>
    props[propName] || fallbackVal;
export const ifProp =
  (propFlagName: string, ifVal: string | number | TIfPropVal, elseVal: string | number | TIfPropVal = '') =>
  (props: any) =>
    props[propFlagName] ? ifVal : elseVal;

export const mediaUpTo = (upTo: number, cssItem: string | FlattenSimpleInterpolation) => css`
  @media only screen and (max-width: ${upTo}px) {
    ${cssItem};
  }
`;

export const lightenColor = function(color:string, percent:number) {
  let num = parseInt(color,16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      B = (num >> 8 & 0x00FF) + amt,
      G = (num & 0x0000FF) + amt;

  return (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255)).toString(16).slice(1);
};
