import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMenu } from '../../../types/Menu';
import { sidebarApi } from './actions';

const initialState : IMenu = {
  popup: [],
  horizontal: [],
  vertical: [],
};

const slice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(sidebarApi.endpoints.getMenuTree.matchFulfilled,
      (state, { payload: { popup, vertical, horizontal } }: PayloadAction<any>) => {
        state.popup = popup;
        state.vertical = vertical;
        state.horizontal = horizontal;
      });
    builder.addMatcher(sidebarApi.endpoints.getMenuTree.matchRejected,
      (state, { payload: { popup, vertical, horizontal } }: PayloadAction<any>) => {
        state.popup = popup;
        state.vertical = vertical;
        state.horizontal = horizontal;
      });
  },
});

export default slice.reducer;
