import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IMenu } from '../../../types/Menu';
import { RootState } from '../../../store';

const URL_PREFIX = '/menu-hierarchy'; // todo: move to config
const METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const sidebarApi = createApi({
  reducerPath: 'sidebarApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api/`,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).auth;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getMenuTree: builder.query<IMenu, any>({
      query: () => ({
        url: `${URL_PREFIX}/tree`,
        method: METHODS.GET,
      }),
    }),
  }),
});

export const { useGetMenuTreeQuery } = sidebarApi;
