import styled, { css } from 'styled-components/macro';
import { Input } from 'antd';
import { getColor, getColorIf, getProp, ifProp } from 'utils/helpers/styleHelpers';
import { ICustomInputProps } from './CustomInput.types';

export const SCustomInput = styled(Input)<ICustomInputProps>`
  background: ${({ theme, bgColor }) => (bgColor ? theme[bgColor] : 'transparent')};
  padding:  ${getProp('padding')};
  margin-bottom: ${getProp('marginBottom')}px;
  margin-right: ${getProp('marginRight')}px;
  border-radius: 100px !important;
  height: ${getProp('height')}px;
  width: 100%;
  font-size: 16px !important;
  
  ::placeholder {
    color: ${getColor('charcoal60')};
  }

  :hover, :focus, :active {
    background: ${({ theme, bgColor }) => (bgColor ? theme[bgColor] : 'transparent')};
  }

  .ant-input {
    color: ${getColor('charcoal60')} !important;
    font-size: 16px !important;
  }

  &.ant-input-status-error, &.ant-input-borderless.ant-input-status-error {
    border: 1px solid ${getColor('red')}) !important;
  }

  ${ifProp(
    'isSuccess',
    css`
      border: 1px solid ${getColor('green')} !important;
    `
  )}};

  .anticon {
    color: ${getColor('blue')};
  }

  .ant-input-prefix {
    margin-right: 10px;
  }
  
  
  ${(props) =>
    props.inputTheme !== 'sms-code' &&
    ifProp(
      'isError',
      css`
        border: 1px solid ${getColor('red')} !important;
      `
    )};
  
  ${(props) =>
    props.inputTheme === 'sms-code' &&
    css`
      background: transparent;
      border-bottom: 1.4px solid ${getColorIf('isEmpty', 'blue', 'charcoal')} !important;
      border-radius: 0 !important;
      padding: 10px;
      line-height: 31px;
      font-size: 24px;
      max-width: 50px;

      &:focus {
        border-bottom-color: ${getColor('blue')} !important;
      }

      ${ifProp(
        'isError',
        css`
          color: ${getColor('red')};
          border-bottom-color: ${getColor('red')} !important;
        `
      )}
    `}

    :disabled {
      background: ${getColor('charcoal10')};
    }
`;
