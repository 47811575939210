import React from 'react';
import { Typography } from 'antd';
import packageJson from '../../../../../package.json';
import { SAddress } from './AuthLayoutFooter.styles';

const { Paragraph } = Typography;

export const AuthLayoutFooter: React.FC = () => (
  <SAddress>
    <Paragraph>{packageJson.version}</Paragraph>
    <Paragraph>Copyright 2022 Percapita. All Rights Reserved.</Paragraph>
  </SAddress>
);
