import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { ThemeController } from './ThemeController';
import { GlobalStyle } from './styles/GlobalStyle';

const container = document.getElementById('root')!;

const root = createRoot(container);

root.render(
    <React.StrictMode>
        <ThemeController>
            <GlobalStyle/>
            <App/>
        </ThemeController>
    </React.StrictMode>,
);
