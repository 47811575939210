import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { InboxOutlined } from '@ant-design/icons';

import { Layout, UploadFile } from 'antd';
import type { UploadProps } from 'antd';
import { message } from 'antd';


import Dragger from 'antd/lib/upload/Dragger';
import { useLogoutMutation } from '../../store/authentication/authentication.actions';

const AppPage: React.FC = () => {
  const {
    Header, Footer, Content,
  } = Layout;

  const navigate = useNavigate();
  const { isLogged, token } = useSelector((state: RootState) => state.auth);


  useEffect(() => {
    if (!isLogged) {
      navigate('/login', { replace: true });
    }
    //eslint-disable-next-line
    }, [isLogged]);

    const validateFileType = ({ type, name }: UploadFile, allowedTypes: string[] = ['text/csv']) => {
        return allowedTypes.includes(type!);
    };

    const props: UploadProps = {
        name: 'file',
        multiple: true,
        action: `${process.env.REACT_APP_API_URL}/api/channel-partner-admin/upload`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        beforeUpload(file) {
            const isAllowedType = validateFileType(file);
            if (!isAllowedType) {
                message.error(`${file.name} is not CSV file`);
                return false;
            }
            return true;
        },
        onChange(info:any) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const [logout] = useLogoutMutation();

    const _logout = () => {
        logout(true)
    }

  return (
    <Layout style={{ minHeight: '100vh' }}>
        <Header style={{backgroundColor:'#00BCD4', display:'flex', flexDirection: 'row-reverse'}}>
            <span style={{cursor: 'pointer'}} onClick={_logout}>Logout</span>
        </Header>
        <Content style={{backgroundColor:'#FFFFFF', padding: '30px'}}>
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Channel partner upload</p>
                <p className="ant-upload-hint">Click or drag file to this area to upload</p>
            </Dragger>
        </Content>
        <Footer style={{backgroundColor: 'aqua'}}></Footer>
    </Layout>
  );
};

export default AppPage;
