import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LocalStorage } from 'services';
import { ClientDetails } from 'types';
import { RootState } from 'store';
import { authApi } from './authentication.actions';
import { getCookie, setCookie } from '../../utils/cookies';
import { jsonParse } from '../../utils/common';

const clientData = LocalStorage.get('clientData');

export interface AuthState {
  isLogged: boolean
  clientDetails: ClientDetails,
  token: string
}

const authFromCookie = jsonParse(getCookie('auth') || '{}');

const initialState: AuthState = {
  isLogged: !!authFromCookie.token,
  clientDetails: clientData || {} as ClientDetails,
  token: authFromCookie.token || ''
};

const authenticationSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.getAuthToken.matchFulfilled,
      (state, { payload: { token } }: PayloadAction<{ token: string }>) => {
        setCookie('auth', JSON.stringify({ token }), 10);
        state.isLogged = true;
        state.token = token;
      });
    builder.addMatcher(authApi.endpoints.getAuthToken.matchRejected, (state) => {
      state.isLogged = false;
    });
    builder.addMatcher(authApi.endpoints.logout.matchFulfilled,
      (state) => {
        state.isLogged = false;
        state.token = '';
      });
    builder.addMatcher(authApi.endpoints.logout.matchRejected, (state) => {
      state.isLogged = false;
    });
  },
});

export default authenticationSlice.reducer;

export const selectCurrentUserToken = (state: RootState) => state.auth.token;
