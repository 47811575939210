import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { selectCurrentUserToken } from 'store/authentication/authentication.slice';

const PrivateRoute = () => {
  const token = useSelector(selectCurrentUserToken);
  return token ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;