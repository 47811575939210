import styled from 'styled-components/macro';
import { Layout as LayoutLibrary } from 'antd';

export const SLayout = styled(LayoutLibrary)`
  min-height: 100vh;
  background: #3E4FE5;
  position: relative;

  &:before {
    content: '';
    width: 23.7rem;
    height: 25.5rem;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const SAuthLayoutHeaderWrapper = styled('div')``;

export const SAuthLayoutFooterWrapper = styled('div')`
  flex: 0 0 auto;
  margin-top: auto;
  position: fixed;
  bottom: 0;
  left:0;
  right: 0;
`;

export const SLayoutInner = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  bottom: 0;
  width: 100%;
  min-height: 709px;
  z-index: 1;
  padding: 42px 33px 0;
  background: #F3EBDB;
  border-radius: 25px 25px 0 0;
`;

export const SLayoutContent = styled(LayoutLibrary.Content)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1 0 auto;
`;

export const SExtendedLayout = styled.div`
  background: #F3EBDB;
  margin-top: -130px;
  height: 200px;
`;
