import React from 'react';
import { useTheme } from 'styled-components';
import { SCustomButton, BtnLinkWrapper } from './CustomButton.styles';
import { ICustomButtonProps } from './CustomButton.types';

export const CustomButton: React.FC<ICustomButtonProps> = ({
  children,
  marginBottom = 0,
  marginTop = 0,
  uppercase = false,
  disabled = false,
  fluid = false,
  textColor = 'white',
  bgColor = 'blue',
  linkTo = '',
  buttonSize = 'average',
  ...props
}) => {
  const theme = useTheme();

  const BtnComponent = (
    <SCustomButton
      {...props}
      marginBottom={linkTo ? 0 : marginBottom}
      marginTop={linkTo ? 0 : marginTop}
      uppercase={uppercase}
      disabled={disabled}
      fluid={fluid}
      buttonSize={buttonSize}
      textColor={textColor}
      bgColor={bgColor}
    >
      {children}
    </SCustomButton>
  );

  return (
    <>
      {
        (linkTo ? (
          <BtnLinkWrapper
            className="btn-link-wrapper"
            to={linkTo}
            marginTop={marginTop}
            marginBottom={marginBottom}
            fluid={fluid}
          >
            {BtnComponent}
          </BtnLinkWrapper>
        ) : (
          BtnComponent
        ))}
    </>
  );
};
