import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetAuthTokenMutation } from 'store/authentication/authentication.actions';
import { LoginFormValues } from 'types';
import WebWorker from 'utils/web-worker';
import authWorker from 'utils/auth-worker';
import LoginForm from './Form';
import { AuthLayoutHeader } from './AuthLayoutHeader/AuthLayoutHeader';
import {
    SAuthLayoutFooterWrapper,
    SAuthLayoutHeaderWrapper, SExtendedLayout, SLayout,
    SLayoutInner,
    SLayoutContent
} from '../../components/layout/AuthLayout/AuthLayout.styles';
import { Col, Row } from 'antd';
import { AuthLayoutFooter } from '../../components/layout/AuthLayout/AuthLayoutFooter/AuthLayoutFooter';

const LoginPage: React.FC = () => {
    const navigate = useNavigate();
    const [requestToken] = useGetAuthTokenMutation();
    const [error, setError] = useState('');

    const worker = new WebWorker(authWorker);

    const onFinish = async (values: LoginFormValues) => {
        try {
            const { token } = await requestToken(values).unwrap();
            if (token) {
                worker.postMessage({ action: 'SET', token });
                navigate('/', { replace: true });
            }
        } catch (err: any) {
            // eslint-disable-next-line no-console
            if (err?.data?.Error) {
                setError(err.data.Error);
            } else {
                setError('Log in failed due to unknown error');
            }
        }
    };

    return (
        <SLayout>
            <SAuthLayoutHeaderWrapper>
                <AuthLayoutHeader/>
            </SAuthLayoutHeaderWrapper>
            <SLayoutInner>
                <Row>
                    <Col xs={{ span: 22, offset: 1 }} md={{ span: 12, offset: 6 }} lg={{ span: 12, offset: 6 }}>
                        <SLayoutContent>
                            <LoginForm onFinish={onFinish} error={error}/>
                        </SLayoutContent>
                        <SAuthLayoutFooterWrapper>
                            <AuthLayoutFooter/>
                        </SAuthLayoutFooterWrapper>
                    </Col>
                </Row>
            </SLayoutInner>
            <SExtendedLayout/>
        </SLayout>

    );
};

export default LoginPage;
