import styled from 'styled-components/macro';

export const SAddress = styled.div`
  flex: 0 0 auto;
  font-size: 11px;
  text-align: center;
  margin-bottom: 45px;
  padding-top: 30px;

  .ant-typography {
    color: #868383;
    margin-bottom: 0;
  }
`;
