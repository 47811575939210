import { createGlobalStyle } from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Poppins', Arial, sans-serif;
    font-size: 36px;
  }
  
  #root {
    height: 100%;
  }

  .ant-modal-wrap {
    overflow: hidden;
  }
  
  .ant-typography {
    a {
      color: ${getColor('blue')};

      &:hover {
        color: ${getColor('blue')};
      }
    }
  }

  .ant-form-item {
    
    &-explain{
      padding: 10px 26px;
    }
    
    &-label {
      padding: 11px 27px !important;
      font-size: 16px !important;
    }
    
  }

  #svg-icon-sprite {
    display: none;
  }
`;
