export const theme = {
  blue: '#3E4FE5',
  beige: '#F3EBDB',
  cream: '#F9F4E8',
  orange: '#F27C4F',
  green: '#5BB398',
  purple: '#C7579A',
  red: '#DF4F4F',
  charcoal: '#353131',
  charcoal60: '#868383',
  charcoal50: '#9A9898',
  charcoal10: '#EAEAEA',
  grey: '#AEADAD',
  white: '#FFFFFF',
  black: '#000000',
  navigationShadow: 'linear-gradient(180deg, rgba(243, 235, 219, 0) 0%, #F9F4E8 100%)',
};

export type TThemeColor = keyof typeof theme;
