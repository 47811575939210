import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthLoginResponse, LoginFormValues } from 'types';
import { RootState } from 'store';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api/auth`,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).auth;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    validateToken: builder.query<any, string>({
      query: (token) => `/validate-token/?token=${token}`,
    }),
    getAuthToken: builder.mutation<AuthLoginResponse, LoginFormValues>({
      query: (body) => ({
        url: '/login',
        method: 'POST',
        body,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: '/logout',
        method: 'POST',
      }),
    }),
    // example of protected query
    protected: builder.mutation({
      query: () => 'protected',
    }),
  }),
});

export const {
  useValidateTokenQuery, useGetAuthTokenMutation, useLogoutMutation, useProtectedMutation,
} = authApi;