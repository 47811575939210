export const setCookie = (name: string, value = '', days = 0) => {
  let expires = '';
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = `${name}=${value}${expires}; path=/`;
};

export const getCookie = (cookieName: string): string | undefined => {
  let cookie:Record<string, any> = {};
  if (typeof window !== 'undefined') {
    document.cookie.split(';').forEach(function (el) {
      let [key, value] = el.split('={');
      cookie[key.trim()] = `{${value}`;
    });
  }

  return cookie[cookieName];
};

export const eraseCookie = (name: string) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};
