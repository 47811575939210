import React from 'react';
import { SBrand } from './AuthLayoutHeader.styles';
import { PercapitaLogo } from '../../../components/PercapitaLogo/PercapitaLogo';

export const AuthLayoutHeader: React.FC = () => {
  return (
    <SBrand>
      <PercapitaLogo isWhite/>
    </SBrand>
  );
};
