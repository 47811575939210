import styled from 'styled-components/macro';
import { Layout as LayoutLibrary } from 'antd';
// import { rgba } from 'polished';

const Layout = styled(LayoutLibrary)`
  min-height: 100vh;
  background: #3E4FE5;
  position: relative;

  &:before {
    content: '';
    width: 23.7rem;
    height: 25.5rem;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const Brand = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 6.4rem;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1.6rem;
  gap: 1.4rem;

  a {
    font-weight: 600;
  }
`;

export const SInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 81px;

  .ant-form-item {
    margin: 0;

    &-label {
      padding: 0 0 11px 15px;
    }
  }
`;

export { Layout, Brand, Actions };
